<template>
  <div class="main">
    <Navbar/>
    <Socials/>
    <div class="py-5">
      <div class="py-5">
        <div class="py-5">
          <h1>Dokumentumok</h1>
          <div class="py-5">
            <b-row>
              <b-col cols="2"></b-col>
              <b-col>
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <th scope="row">Alapszabály</th>
                    <td><a target="_blank" href="https://endog-public.s3.eu-west-1.amazonaws.com/endog-alapszabaly.pdf">Megnézem</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Módosított Alapszabály</th>
                    <td><a target="_blank" href="https://endog-public.s3.eu-west-1.amazonaws.com/endog-modositott-alapszabaly.pdf">Megnézem</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Tevékenységünk Támogatója</th>
                    <td><a target="_blank" href="https://endog-public.s3.eu-west-1.amazonaws.com/endog-tevekenyseg-tamogato.pdf">Megnézem</a></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col cols="2"></b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Navbar from "../components/Navbar";
import Socials from "../components/Socials";import Contacts from "../components/Contacts"

export default {
  name: 'Documents',
  props: {},
  components: {
    Navbar,
    Socials
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
.button-color {
  background-color: #06973E;
}
</style>