<template>
  <div class="main">
    <Navbar/>
    <Contacts/>
  </div>
</template>

<script>

import Navbar from "../components/Navbar";
import Contacts from "../components/Contacts"

export default {
  name: 'ContactUs',
  props: {},
  components: {
    Contacts,
    Navbar
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
.button-color {
  background-color: #06973E;
}
</style>