<template>
  <b-card-group deck>
    <b-card :img-src="img_url" :img-alt="title" :title="title" img-top>
      <b-card-text align="left">
        <span v-html="text"></span>
      </b-card-text>
      <hr/>
      <div class="text-right">
        <b-button :to="'/news/' + news_id_and_version" variant="success" size="s" class="mx-1" type="submit">Tovább
        </b-button>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
export default {
  name: 'NewsArticle',
  props: ['img_url', 'news_text', 'id', 'title'],
  data() {
    return {
      news_id_and_version: this.id.replace("#", "/"),
    }
  },
  computed: {
    text() {
      return this.news_text.replaceAll("\n", "<br/>");
    }
  }
}
</script>
