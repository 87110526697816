<template>
  <div>
    <b-container class="py-5">
      <b-row class="py-5">
        <b-col>
          <div class="text-left py-5">
            Kapcsolat:
            <br>
            <br>
            Bankszámlaszám: 11745114-23915297 (OTP Bank)
            <br>
            IBAN: HU22117451142391529700000000 OTPVHUHB
            <br>
            Paypal: endogegyesulet@gmail.com
            <br>
            <br>
            Cím: 5137 Jászkisér, Fő út 4.
            <br>
            Email: endogegyesulet@gmail.com
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-1">
          <b-button size="lg" variant="primary" class="mb-2" href="https://www.facebook.com/endogegyesulet">
            <b-icon icon="facebook" aria-label="Facebook">Facebook</b-icon>
          </b-button>
        </b-col>
        <b-col class="col-1">
          <b-button size="lg" variant="danger" class="mb-2" href="https://www.instagram.com/endoganimalrescue/">
            <b-icon icon="instagram" aria-label="Instagram">Instagram</b-icon>
          </b-button>
        </b-col>
        <b-col class="col-5"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Contacts"
}
</script>