<template>
  <div class="cta-container shadow-lg">
    <b-container class="py-3">
      <b-row>
        <b-col align="center">
          <h2 class="d-none d-lg-block">Hogyan segíthetsz?</h2>
          <h3 class="d-lg-none">Segíts, hogy segíthessünk!</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col align="center">
          <p>Hisz minden állat megérdemel egy esélyt.</p>
        </b-col>
      </b-row>
      <b-container>
        <b-row align="center">
          <b-col cols="3" class="d-none d-lg-block"></b-col>
          <b-col>
            <b-card bg-variant="success" text-variant="light" class="text-center">
              <b-card-header>
                <span>Támogass minket </span>
              </b-card-header>
              <b-card-body>
                <b-card-text align="left">
                  Bankszámlaszám: 11745114-23915297 (OTP Bank)<br>
                  IBAN: HU22117451142391529700000000 OTPVHUHB<br>
                  Paypal: endogegyesulet@gmail.com<br>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="3" class="d-none d-lg-block"></b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CallToAction'
}
</script>

<style>
.cta-container {
  background-color: #FFFFFF;
}

</style>