<template>
  <div>
    <b-carousel
        id="carousel"
        v-model="slide"
        :interval="4000"
        indicators
        img-width="1024"
        img-height="1024"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="shadow-lg d-none d-lg-block"
    >
      <b-carousel-slide v-for="image in images" :key="image.name"
                        :caption=image.caption
                        :img-src=image.url
                        class="carousel-image"
      >
      </b-carousel-slide>
    </b-carousel>

    <b-carousel
        id="carousel"
        v-model="slide"
        img-width="1024"
        img-height="480"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="d-lg-none"
    >
      <b-carousel-slide :img-src=images[0].url
                        class="carousel-image"
      >
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>

export default {
  name: 'Carousel',
  data() {
    return {
      slide: 0,
      sliding: null,
      images: [
        {
          "name": "c-first",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyn1-cover-1.jpeg",
          "caption": "Segíts, hogy segíthessünk!"
        },
        {
          "name": "c-second",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyo0-cover-2.jpeg",
          "caption": null
        },
        {
          "name": "c-third",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyok-cover-3.jpeg",
          "caption": null
        },
        {
          "name": "c-fourth",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwypt-cover-4.jpeg",
          "caption": null
        },
        {
          "name": "c-fifth",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyqb-cover-5.jpeg",
          "caption": null
        },
        {
          "name": "c-sixth",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyrc-cover-6.jpeg",
          "caption": null
        },
        {
          "name": "c-seventh",
          "url": "https://dfxxiaxr2oz4r.cloudfront.net/files/8la6pwyrz-cover-7.jpeg",
          "caption": null
        },
      ]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  },
}
</script>

<style>
.carousel-image {
  object-fit: cover;
  max-height: 1000px;

}

.carousel-mobile-text {
  text-shadow: black;
}
</style>