<template>
  <div class="main">
    <Carousel/>
    <Socials/>
    <CallToAction/>
    <NewsSection v_align="baseline"/>
    <Rescues num_results=4 title="Védenceink" show_more="true" status="LOOKING_FOR_OWNER"/>
    <Sponsors/>
    <Footer/>
  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import NewsSection from "../components/NewsSection";
import CallToAction from "../components/CallToAction";
import Socials from "../components/Socials"
import Footer from "../components/Footer"
import Sponsors from "../components/Sponsors";
import Rescues from "../components/Rescues";

export default {
  name: 'Main',
  props: {},
  components: {
    Rescues,
    Carousel,
    NewsSection,
    CallToAction,
    Socials,
    Footer,
    Sponsors,
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
.button-color {
  background-color: #06973E;
}
</style>