<template>
  <div class="py-4 sponsors">
    <b-container fluid class="sponsors-bg">
      <b-row align="center">
        <b-col>
          <h2 class="text-white">Támogatóink</h2>
        </b-col>
      </b-row>
      <b-row class="py-5">
        <b-col cols="2"></b-col>
        <b-col>
          <b-img thumbnail v-bind="imgProps" src="https://dfxxiaxr2oz4r.cloudfront.net/files/8la6qfxun-oklogo.png" alt=""></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="imgProps" src="https://dfxxiaxr2oz4r.cloudfront.net/files/8la6qfxv7-vetemedic.jpeg" alt=""></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="imgProps" src="https://dfxxiaxr2oz4r.cloudfront.net/files/8ldytbinf-ME_logo_szines_HU_440x270.png" alt=""></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="imgProps" src="https://dfxxiaxr2oz4r.cloudfront.net/files/8ldytb89j-BGA.png" alt=""></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="imgProps" src="https://dfxxiaxr2oz4r.cloudfront.net/files/8ldytccxf-NEA.png" alt=""></b-img>
        </b-col>
        <b-col colrs="2"></b-col>
      </b-row>
      <b-row class="py-5 display-flex">
        <b-col cols="2"></b-col>
        <b-col>
          <b-img thumbnail v-bind="mainProps" rounded="circle" alt="Circle image"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="mainProps" rounded="circle" alt="Circle image"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="mainProps" rounded="circle" alt="Circle image"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="mainProps" rounded="circle" alt="Circle image"></b-img>
        </b-col>
        <b-col>
          <b-img thumbnail v-bind="mainProps" rounded="circle" alt="Circle image"></b-img>
        </b-col>
        <b-col colrs="2"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Sponsors",
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 150,
        height: 150,
        class: 'm1'
      },
      imgProps: {
        minWidth: 150,
        minHeight: 150,
        width: 150,
        height: 150,
        class: 'm1'
      }
    }
  }
}
</script>

<style>
.sponsors {
  background-color: #26A65B;
}

.sponsors-bg {
  background-image: url("https://djrdm4moq2kdo.cloudfront.net/mancsok.png");
  background-repeat: no-repeat;
  background-size: 70% 100%;
  background-position-x: 40%;
  max-width: 1440px;
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
}

</style>