<template>
  <div class="main">
    <Navbar/>
    <Socials/>
    <div class="py-5">
      <div class="py-5">
        <b-row>
          <b-col cols="3"></b-col>
          <b-col cols="6">
            <p class="py-5 text-left">
              Egyesületünk elsősorban kóbor és kóborló, valamint rossz körülmények közül kiemelt kutyák és
              cicák mentésével, valamint környezetvédelemmel foglakozó szervezet.
              Jászkisér Város Önkormányzatával szoros együttműködésben, állatvédelmi szemlélettel próbáljuk
              megoldani az országosan és így településünkön is nagy problémát jelentő kóbor kutya, valamint
              állatkínzással tartott ebtartás kérdését.
              2019. január 28-i megalakulásunk óta évente kb. 100 kóbor, kóborló, állatvédelmi hatósági
              eljárásban elkobzott ebet mentettünk meg az altatás elől és juttatunk új tulajdonosokhoz.
              <br>
              <br>
              Egyesületünk átlagosan 30-40 ebet és 10-15 macskát gondoz a Menedéken és ideiglenes
              gazdáknál. Elsősorban nem fajtamentő tevékenységet folytatunk, így nálunk minden eb egyenlő
              feltételekkel indul az új élete felé. Munkánk során a hányatott sorsú, alulszocializált, alultáplált,
              bántalmazott ebek testi és lelki rehabilitációját folytatjuk, amelynek keretében hatékony állatorvosi
              ellátás mellett az ebeket napi séták során ismertetjük meg a pórázon sétával, kompatibilitási tesztek
              során felmérjük együttműködési készségeiket más kutyákkal és cicákkal annak érdekében, hogy
              minden egyes mentett élőlény tekintetében a lehető legjobb otthont találjuk számukra.
              <br>
              <br>
              A hazai örökbeadások mellett nemzetközi, főként német, osztrák és svájci civil szervezeti
              kapcsolatokkal rendelkezünk, amelynek köszönhetően évente kb. 50-100 kóbor, kóborló és
              állatvédelmi eljárás keretében, rossz körülmények közül mentett kutyát sikerül Németországba,
              Ausztriába, Svájcba, Hollandiába, Belgiumba és Magyarországra gazdásítanunk.
              <br>
              <br>
              Együttműködés keretében az alábbi szervezetekkel ápolunk kapcsolatot és dolgozunk együtt:
              Tierhilfe born to live ev., Sissi Spánielmentő Egyesület, Szolnoki Városi Állatotthon Alapítvány,
              Ébredő Bolygó Alapítvány
              <br>
              <br>
              Szerződéses kapcsolatban vagyunk a Vinkli Vet. és Vet-e-medic Állatorvosi rendelőkkel, ezáltal
              szakértő orvosok segítségével igyekszünk biztosítani az ebek szakszerű ellátását.
              <br>
              <br>
              Egyesületünk igyekszik jelentős szerepet vállalni a felelős állattartás népszerűsítésében,
              rendszeresen ismeretterjesztő programokat tartunk általános iskolás, óvodás csoportoknak, akik
              interaktív foglalkozások keretében ismerkedhetnek meg a gondozásunkban lévő állatokkal, a
              felelős állattartás és a környezetvédelem, a fenntartható fejlődés fontosságával.
              A szemléletformáló tevékenységünk keretében felnőtteknek és gyermekeknek lehetőséget
              biztosítunk kutya sétáltatásra, közös szabadidős tevékenység eltöltésére.
              Különböző rendezvényeken pavilonunkkal kitelepülve próbáljuk az állatszerető embereket és
              érdeklődőket a tevékenységünkbe bevonni, a problémák fontosságára a figyelmet felhívni.
              Szervezünk – adománygyűjtési céllal – jótékonysági koncerteket és futóversenyeket szervez.
              <br>
              <br>
              <b>Tevékenységünkről, mentéseinkről, programjainkról és munkánkról rendszeresen hírt
                adunk az @endogeygesulet facebook, valamint az @endoganimalrescue instagram
                oldalunkon.</b>
              <br>
              <br>
              Egyesületünk örömmel fogadja a segítőket, ideiglenes befogadókat, fuvarosokat, szimpatizánsokat,
              gyakorlatilag minden segíteni akaró embert, tartsatok velünk, legyetek Ti is részesei valami jónak,
              mert jónak lenni jó!
              Tagnak e-mailben lehet jelentkezni, a tagdíj havi 1000 Ft, az első jelentkezőket a májusban
              esedékes közgyűlés fogadja majd el. Az e-mail címünk az endogegyesulet@gmail.com.
              Köszönjük!
            </p>
          </b-col>
          <b-col cols="3"></b-col>
        </b-row>
      </div>
      <b-row align-v="center" align="center" align-content="center">
        <b-col>
          <b-card>
            <b-card-body>
              <img v-for="(image, i) in images" :key="i" alt=""
                   class="gallery-image" :src="image" @click="index = i">
              <vue-gallery-slideshow :images="images" :index="index"
                                     @close="index = null"></vue-gallery-slideshow>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4"></b-col>
        <b-col v-for="(image, i) in images" :key="i" cols="1">

        </b-col>
        <b-col cols="2"></b-col>
      </b-row>
    </div>
    <Footer/>
  </div>
</template>


<script>

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Socials from "../components/Socials";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'About',
  props: {},
  components: {
    Footer,
    Navbar,
    Socials,
    VueGallerySlideshow
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
    return {
      images: [
        "https://endog-public.s3.eu-west-1.amazonaws.com/about-us-1.jpeg",
        "https://endog-public.s3.eu-west-1.amazonaws.com/about-us-2.jpeg",
        "https://endog-public.s3.eu-west-1.amazonaws.com/about-us-3.jpeg",
        "https://endog-public.s3.eu-west-1.amazonaws.com/about-us-4.jpeg"
      ],
      index: null
    }
  }
}
</script>

<style>
.gallery-image {
  width: 300px;
  height: 300px;
  object-fit: scale-down;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

</style>