<template>
  <div class="rescue-details">
    <div class="py-5">
      <div class="py-5">
        <b-container fluid>
          <b-row align="center" class="py-5">
            <b-col>
              <h1 class="name">{{ getVedenc.data.nev }}</h1>
            </b-col>
          </b-row>
          <b-row align-v="center" align="center" align-content="center">
            <b-col>
              <b-card>
                <b-card-body align="left">
                  <div v-html="vedencDescription"></div>
                  <br/>
                  <span><b>EN-DOG EGYESÜLET</b></span><br/>
                  <span>Bankszámlaszám: 11745114-23915297 OTP BANK IBAN HU22 117451142391529700000000 OTPVHUHB</span><br/>
                  <span>Paypal: endogegyesulet@gmail.com</span><br/>
                  <span>Adó 1%: 19153319-1-16</span>
                  <br/>
                  <img v-for="(image, i) in getVedenc.data.images.concat(getVedenc.data.previewImage)" :key="i" alt="" class="gallery-image" :src="image" @click="index = i">
                  <vue-gallery-slideshow :images="getVedenc.data.images.concat(getVedenc.data.previewImage)" :index="index"
                                         @close="index = null"></vue-gallery-slideshow>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4"></b-col>
            <b-col v-for="(image, i) in getVedenc.data.images" :key="i" cols="1">

            </b-col>
            <b-col cols="2"></b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'RescueDetails',
  props: {},
  components: {
    VueGallerySlideshow
  },
  apollo: {
    getVedenc: {
      query: gql`
        query getVedenc($id: ID!) {
          getVedenc(where:{id: $id}) {
            data {
              id
              nev
              images,
              previewImage,
              description,
              createdOn
            },
            error {
              message,
              data,
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id + "#" + this.$route.params.version
        };
      },
    },
  },
  computed: {
    vedencDescription() { return this.getVedenc.data.description.replaceAll("\n", "<br/>"); }
  },
  data() {
    document.body.style.backgroundColor = "#26A65B";

    return {
      getVedenc: {'data': []},
      index: null
    };
  },
}
</script>

<style>
.preview-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.gallery-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.name {
  color: #FFFFFF;
}
</style>