<template>
  <div class="py-5">
    <div class="py-5">
      <Rescues num_results=4 title="Védenceink" show_more="false" status="ADOPTED"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Rescues from "../components/Rescues";

export default {
  name: 'RescuesAdopted',
  props: {},
  components: {
    Rescues,
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
.button-color {
  background-color: #06973E;
}
</style>