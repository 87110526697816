<template>
  <b-card-group deck>
    <b-card class="card-img-overlay-custom" overlay img-width="180" img-height="180" style="max-width: 20rem;"
            :img-src="img_url"
            img-alt="Card image">
      <div class="rescue-details">
        <b-card-text class="text-white text-left name-tag">
          <h2>{{ rescue_name }}</h2>
        </b-card-text>
        <hr class="rescue-details-divider" color="white">
      </div>
      <router-link :to="'/rescues/' + rescue_id_and_version" class="stretched-link"></router-link>
    </b-card>
  </b-card-group>
</template>

<script>
export default {
  name: 'NewsArticle',
  props: ['img_url', 'rescue_name', 'rescue_id'],
  data() {
    return {
      rescue_id_and_version: this.rescue_id.replace("#", "/"),
    }
  }
}
</script>

<style scoped>
.card-img {
  object-fit: cover;
}

.card-img-overlay-custom {
  background-color: black;
  border-radius: 4px;
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12);
  cursor: pointer;
}

.card-img-overlay-custom:hover {
  transform: scale(1.05);
}

.rescue-details {
  padding-top: 6em;
}

.rescue-details-divider {
  margin-top: -1em;
  opacity: 0.6;
}

.name-tag {
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

</style>