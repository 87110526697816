<template>
  <div class="rescues-container d-none d-lg-block">
    <b-container fluid>
      <b-row align="center" class="py-3">
        <b-col>
          <h2>{{ title }}</h2>
        </b-col>
      </b-row>
      <b-row v-for="slice in slicedArrays" :key="slice" align="center" class="py-2">
        <b-col cols="2">
        </b-col>
        <b-col v-for="rescue in slice" :key="rescue.id">
          <RescueCard :img_url=rescue.previewImage
                      :rescue_name=rescue.nev
                      :rescue_id="rescue.id"></RescueCard>
        </b-col>
        <b-col cols="2"></b-col>
      </b-row>
      <div class="py-2" v-if="show_more === 'true'">
        <b-row align="center">
          <b-col>
            <b-button variant="success">Megnézem a többieket is</b-button>
          </b-col>
        </b-row>
      </div>
      <div class="py-3"></div>
    </b-container>
  </div>
</template>

<script>
import RescueCard from "./RescueCard";
import gql from 'graphql-tag';

export default {
  name: "Rescues",
  components: {
    RescueCard
  },
  props: ['title', 'num_results', 'show_more', 'pagination_cursor', 'status'],
  apollo: {
    listVedencs: {
      query: gql`
        query ListVedencs($limit: Int!, $after: String, $status: String) {
          listVedencs(sort: createdOn_DESC, limit: $limit, after: $after,  where: {statusz: $status}) {
            data {
              id
              nev
              images,
              previewImage,
              description,
              createdOn
            },
            meta {
              cursor,
              hasMoreItems
            }
            error {
              message,
              data,
            },
          }
        }
      `,
      variables() {
        return {
          limit: parseInt(this.num_results),
          after: this.pagination_cursor || null,
          status: this.status || "LOOKING_FOR_OWNER"
        }
      }
    },
  },
  computed: {
    slicedArrays() {
      let sliceSize = 4;
      return this.listVedencs.data.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / sliceSize)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
    }
  },
  data() {
    return {
      listVedencs: {'data': []},
    };
  },
}
</script>
