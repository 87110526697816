<template>
  <div class="footer py-3">
    <b-container fluid class="shadow-lg">
      <b-row class="text-center" align-v="center">
        <b-col class="align-middle">
          © 2021 - En-Dog Állatmentő és Környezetvédő Egyesület
        </b-col>
        <b-col>
          <div class="text-left py-2">
            <b-icon-credit-card-fill></b-icon-credit-card-fill> Kapcsolat:
            <br>
            <br>
            Bankszámlaszám: 11745114-23915297 (OTP Bank)
            <br>
            IBAN: HU22117451142391529700000000 OTPVHUHB
            <br>
            Paypal: endogegyesulet@gmail.com
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>
.footer {
  color: grey;
}
</style>