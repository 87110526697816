<template>
  <div class="rescue-details">
    <div class="py-5">
      <div class="py-5">
        <b-container fluid>
          <b-row align="center" class="py-5">
            <b-col>
              <h1 class="name">{{ getHir.data.title }}</h1>
            </b-col>
          </b-row>
          <b-row align-v="center" align="center" align-content="center">
            <b-col>
              <b-card>
                <b-card-body align="left">
                  <div v-html="newsPreviewText"></div>
                  <div v-html="newsBodyText"></div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row align-v="center" align="center" align-content="center">
            <b-col>
              <b-card>
                <b-card-body>
                  <img v-for="(image, i) in getHir.data.images.concat(getHir.data.coverImage)" :key="i" alt=""
                       class="gallery-image" :src="image" @click="index = i">
                  <vue-gallery-slideshow :images="getHir.data.images.concat(getHir.data.coverImage)" :index="index"
                                         @close="index = null"></vue-gallery-slideshow>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4"></b-col>
            <b-col v-for="(image, i) in getHir.data.images" :key="i" cols="1">

            </b-col>
            <b-col cols="2"></b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import VueGallerySlideshow from 'vue-gallery-slideshow';


export default {
  name: 'NewsDetails',
  props: {},
  components: {
    VueGallerySlideshow,
  },
  apollo: {
    getHir: {
      query: gql`
        query getHir($id: ID!) {
          getHir(where:{id: $id}) {
            data {
              id
              title
              coverImage
              previewText
              body
              images
            },
            error {
              message,
              data,
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id + "#" + this.$route.params.version
        };
      },
    },
  },
  computed: {
    newsPreviewText() {
      return this.getHir.data.previewText.replaceAll("\n", "<br/>");
    },
    newsBodyText() {
      let retVal = "";
      if (this.getHir.data.body === null) return retVal;
      this.getHir.data.body.forEach(paragraph => {
        retVal += paragraph.data.text += "<br/>"
      });
      return retVal.replaceAll("\n", "<br/>");
    }
  },
  data() {
    document.body.style.backgroundColor = "#26A65B";

    return {
      getHir: {'data': []},
      index: null,
      config: {
        readonly: true
      }
    };
  },
}
</script>

<style>
.preview-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.gallery-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.name {
  color: #FFFFFF;
}
</style>