<template>
  <div class="new-section">
    <b-container class="py-5">
      <b-row align="center">
        <b-col>
          <h1 class="text-white">Friss Híreink</h1>
        </b-col>
      </b-row>

      <div class="d-none d-lg-block">
        <b-row class="py-5 " :align-v="v_align" v-for="chunk in chunkedArticles(3)" :key="chunk">
          <b-col v-for="newsArticle in chunk" :key="newsArticle.name">
            <NewsArticle
                :id="newsArticle.id"
                :img_url=newsArticle.coverImage
                :news_text=newsArticle.previewText
                :title="newsArticle.title"></NewsArticle>
          </b-col>
        </b-row>
      </div>

      <div class="d-lg-none" v-for="chunk in chunkedArticles(1)" :key="chunk">
        <b-row class="py-5 " v-for="newsArticle in chunk" :key="newsArticle.name">
          <b-col>
            <NewsArticle
                :id="newsArticle.id"
                :img_url=newsArticle.coverImage
                :news_text=newsArticle.previewText
                :title="newsArticle.title"></NewsArticle>
          </b-col>
        </b-row>
      </div>

      <div class="py-2" v-if="show_more === 'true'">
        <b-row align="center">
        </b-row>
      </div>
      <div class="py-3"></div>

    </b-container>
  </div>
</template>

<script>
import NewsArticle from "../components/NewsArticle";
import gql from "graphql-tag";

const newsQuery = gql`
        query ListHirs($after: String) {
          listHirs(sort: createdOn_DESC, limit: 3, after: $after) {
            data {
              id
              title
              coverImage
              previewText
            },
            meta {
              cursor,
              hasMoreItems
            },
            error {
              message,
              data,
            }
          }
        }
      `

export default {
  name: 'NewsSection',
  props: ['pagination_cursor', 'show_more', 'v_align'],
  components: {
    NewsArticle
  },
  apollo: {
    listHirs: {
      query: newsQuery,
      variables() {
        return {
          after: this.cursor || null
        }
      }
    },

  },
  mounted() {
    this.scroll()
  },
  methods: {
    chunkedArticles(chunkSize) {
      const chunk = (arr, size) => arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
      const c =  chunk(this.listHirs.data, chunkSize);
      console.log(c)
      return c;
    },
    scroll () {
      window.onscroll = () => {
        if (!this.show_more) {
          return;
        }
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (!bottomOfWindow){
          return;
        }
        if (!this.showMoreEnabled) {
          return;
        }

        this.$apollo.queries.listHirs.fetchMore({
          variables: {
            after: this.listHirs.meta.cursor
          },
          updateQuery: (previousResult, {fetchMoreResult}) => {
            const newData = fetchMoreResult.listHirs.data || [];
            const meta = fetchMoreResult.listHirs.meta;
            this.showMoreEnabled = fetchMoreResult.listHirs.meta.cursor !== null;
            let mergedData = previousResult.listHirs.data;
            newData.forEach(data => {
              let existingIds = mergedData.filter(function(obj) { return obj.id})
              if (!existingIds.includes(data.id)) {
                mergedData.push(data);
              }
            });
            return {
              listHirs: {
                __typename: previousResult.listHirs.__typename,
                data: {data: mergedData, meta: meta}
              }
            }
          }
        })
      }
    }
  },
  data() {
    return {
      listHirs: {'data': []},
      showMoreEnabled: true,
    };
  },
  created(){
    this.cursor = null;
  },

}
</script>

<style>
.new-section {
  background-color: #26A65B;
}
</style>