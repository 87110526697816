<template>
  <b-container>
    <b-sidebar class="d-none d-lg-block" bg-variant="transparent" id="sidebar-1" title="Sidebar" visible no-header
               right>
      <b-container align="right" class="custom-sidebar-container">
        <b-row>
          <b-col>
            <b-button size="lg" variant="primary" class="mb-2" href="https://www.facebook.com/endogegyesulet">
              <b-icon icon="facebook" aria-label="FAcebook"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button size="lg" variant="danger" class="mb-2" href="https://www.instagram.com/endoganimalrescue/">
              <b-icon icon="instagram" aria-label="Instagram"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
    <div class="d-lg-none mobile-socials-container">
      <b-row class="py-1">
      </b-row>
      <b-row align="center">
        <b-col align="right">
          <b-button size="lg" variant="primary" class="mb-2" href="https://www.facebook.com/endogegyesulet">
            <b-icon icon="facebook" aria-label="FAcebook"></b-icon>
          </b-button>
        </b-col>
        <b-col align="left">
          <b-button size="lg" variant="danger" class="mb-2" href="https://www.instagram.com/endoganimalrescue/">
            <b-icon icon="instagram" aria-label="Instagram"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Socials"
}
</script>

<style>
.custom-sidebar-container {
  padding-top: 6rem;
}

#sidebar-1 {
  margin-top: 100px
}

.mobile-socials-container {
  margin-bottom: 0px;

}
</style>