import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './app.scss'
import VueApollo from 'vue-apollo'
import ApolloClient from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {ApolloLink, concat} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import RescueDetails from "./pages/RescueDetails";
import Documents from "./pages/Documents";
import Information from "./pages/Information"
import Donation from "./pages/Donations"
import About from "./pages/About";
import News from "./pages/News"
import NewsDetails from "./pages/NewsDetails"
import RescuesLookingForOwner from "./pages/RescuesLookingForOwner"
import RescuesAdopted from "./pages/RescuesAdopted";
import RescuesDeceased from "./pages/RescuesDeceased";
import RescuesPendingAdoption from "./pages/RescuesPendingAdoption";
import App from './App.vue';
import VueRouter from "vue-router";

Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const httpLink = new HttpLink({
    uri: 'https://dfxxiaxr2oz4r.cloudfront.net/cms/read/hu-HU'
})

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = "ac1e8a01298d2c8ecc649d44395329062369ae06c84f08b3";
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : "",
        },
    });
    return forward(operation);
});

export const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

Vue.use(VueRouter)

const routes = [
    {path: '/contact', name: 'contact-us', component: ContactUs},
    {path: '/rescues/looking-for-owner', name: 'rescue-looking-for-owners', component: RescuesLookingForOwner},
    {path: '/rescues/pending-adoption', name: 'rescue-pending-adoption', component: RescuesPendingAdoption},
    {path: '/rescues/adopted', name: 'rescue-adopted', component: RescuesAdopted},
    {path: '/rescues/deceased', name: 'rescue-deceased', component: RescuesDeceased},
    {path: '/rescues/:id/:version', name: 'rescue-details', component: RescueDetails},
    {path: '/news/:id/:version', name: 'news-details', component: NewsDetails},
    {path: '/news/', name: 'news', component: News},
    {path: '/about', name: 'about', component: About},
    {path: '/documents', name: 'documents', component: Documents},
    {path: '/good-to-know', name: 'good-to-know', component: Information},
    {path: '/donation', name: 'donation', component: Donation},
    {path: '/', name: 'home', component: Home},
]

const router = new VueRouter({routes})

new Vue({
    router,
    apolloProvider,
    render: function (createElement) {
        return createElement(App)
    }
}).$mount('#app')
