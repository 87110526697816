<template>
  <div class="main">
    <Navbar/>
    <Socials/>
    <div class="py-5">
      <div class="py-5">
        <div class=py-5>
          <b-row>
            <b-col cols="3"></b-col>
            <b-col cols="6">
              <p><b>Az örökbefogadás feltételei, menete az EN-DOG Egyesületnél</b></p>
              <p class="py-3 text-left">
                <br>
                <br>

                Az érdeklődőktől részletes bemutatkozó levelet várunk az
                <b>endogegyesulet@gmail.com</b> e-mail címre.
                A bemutatkozó levélből a kutyatartással kapcsolatban minden fontos információ
                kiderül.
                <br>
                <br>
                Hol és milyen körülmények között lakna a kutya?<br>
                Kikkel osztaná meg az életét – van-e idős ember vagy gyermek családtag?<br>
                Van-e másik kutya, vagy cica a családban?<br>
                Mennyi idő jut naponta aktívan a kutyára, mennyi időt kellene egyedül töltenie?<br>
                Hosszabb távollét esetén ki vigyázna rá?<br>
                Van-e már kutyás tapasztalat?<br>
                Van-e kutyaiskola a közelben?<br>
                Kan vagy szuka kutyát szeretnének örökbefogadni?<br>
                <br>
                Fiatal, vagy felnőtt, esetleg idős kutyát szeretnének, stb.
                A leírtaknak megfelelően tudunk az érdeklődőhöz illő kutyát javasolni a
                védenceink közül vagy mérlegelni, hogy az örökbefogadni kívánt eb a családba illik-
                e.
                <br>
                <br>
                <b>Az örökbefogadás általános feltételei az EN-DOG Egyesületnél:</b>
                <br>
                1./ a benti tartás (a kertben a kutya csak felügyelettel lehet kinn, egyedül ott nem
                hagyható);<br>
                2./ megkezdett és adott évre vonatkozóan befejezett oltási program folytatása
                (kölyökkutyák esetében valamennyi kötelező oltás megléte után fogadható örökbe
                az állat);<br>
                3./ ivartalanítási kötelezettség (kölyökkutyák esetében, illetve egyéb, speciális
                helyzetekben – igazolási kötelezettség mellett) – a gazdára váró felnőtt kutyák
                kizárólag ivartalanítva fogadhatók örökbe<br>
                4./ a kullancs és bolha-, valamint a szívféreg megelőző program folytatása;<br>
                5./ az esetleges folyamatban lévő orvosi kezelés folytatása (és természetesen
                minden a jövőben felmerülő egészségügyi probléma haladéktalan kezelése);<br>
                6./ szívférges kutya esetén az egyesületi protokoll betartása<br>
                7./ bármilyen probléma esetén a kutya nem ajándékozható tovább, az egyesülethez
                kell visszakerülnie.<br>
                8./ állatorvosi költségek teljeskörű megtérítése.<br>
                9./ Első alkalommal személyes látogatás az örökbefogadásra kívánt kutyánál.<br>
                10./ Az EN-DOG Egyesület részéről a látogatást követően környezettanulmányt
                tartunk.<br>
                11./ Az örökbefogadást követően személyes látogatás során ellenőrizhetjük az állat
                életkörülményeit.<br>
                <br>
                Egyesületünk <b>nem</b> ad örökbe kutyát:
                <br>
                <br>
                - kinti tartásra, illetve olyan helyre, ahol van kinti tartású és/vagy ivaros másik
                kutya;<br>
                - Idős embereknek speciális feltételekkel adunk örökbe, kölyök és fiatal felnőtt
                kutyákat nem adunk idősebb emberek mellé örökbe azok eltérő igényei
                miatt.<br>
                - Külföldre történő örökbefogadásra a Tierhilfe born to live szervezettel
                történő egyeztetést követően, a kiutazáshoz szükséges dokumentációk
                beszerzését követően van lehetőség.<br>
                <br>
                A kutyáink látogatása biztosított, a jelentkezőkkel erről külön egyeztetünk. Az
                immunizálatlan kölyök nem látogathatók a magas fertőzésveszély miatt.
                <br>
                <br>
                Az örökbefogadásról örökbefogadási szerződés kerül aláírásra, mely a fontosabb
                tudnivalókat és kötelezettségeket tartalmazza, és egyben a tulajdonjog
                átruházásának alapdokumentuma.<br>
                Az örökbefogadott kutya esetében az örökbefogadást követő egy hónapban minden
                sétánál kötelező a nyakörv, hám egyidejű viselése, és mindkettőre külön pórázt kell
                csatolni. Az örökbefogadott kutyát legalább egy hónapig, de legalább a teljes
                behívhatóság / kötődés kialakulásáig a pórázról külső helyszínen – függetlenül
                annak körbekerített vagy szabad voltára – elengedni tilos.<br>
                Az örökbefogadót az örökbefogadást követő első három hónapban, vagy a
                szükséges ideig mentor segíti. A kapcsolattartás kötelező annak érdekében, hogy
                megbizonyosodhassunk a kutya problémamentes beilleszkedésében, vagy probléma
                esetén segítséget tudjunk nyújtani.<br>
              </p>
            </b-col>
            <b-col cols="3"></b-col>
          </b-row>
        </div>
      </div>
      <b-row align-v="center" align="center" align-content="center">
        <b-col>
          <b-card>
            <b-card-body>
              <img v-for="(image, i) in images" :key="i" alt=""
                   class="gallery-image" :src="image" @click="index = i">
              <vue-gallery-slideshow :images="images" :index="index"
                                     @close="index = null"></vue-gallery-slideshow>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4"></b-col>
        <b-col v-for="(image, i) in images" :key="i" cols="1">

        </b-col>
        <b-col cols="2"></b-col>
      </b-row>
    </div>
    <Footer/>
  </div>
</template>


<script>

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Socials from "../components/Socials";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'About',
  props: {},
  components: {
    Footer,
    Navbar,
    Socials,
    VueGallerySlideshow
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
    return {
      images: [
        "https://endog-public.s3.eu-west-1.amazonaws.com/mala-before.jpeg",
        "https://endog-public.s3.eu-west-1.amazonaws.com/mala-after.jpeg",
      ],
      index: null
    }
  }
}
</script>

<style>
.gallery-image {
  width: 300px;
  height: 300px;
  object-fit: scale-down;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

</style>