<template>
  <div class="py-5">
    <NewsSection show_more="true" v_align=""/>
    <Footer/>
  </div>
</template>

<script>
import NewsSection from "../components/NewsSection";

export default {
  name: 'News',
  props: {},
  components: {
    NewsSection,
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
.button-color {
  background-color: #06973E;
}
</style>