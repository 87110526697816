<template>
  <div class="fixed-top">
    <b-navbar toggleable="lg" type="light" variant="light" class="shadow-lg">
      <b-navbar-brand href="/">
        <img src="https://endog-public.s3.eu-west-1.amazonaws.com/logo.png" class="d-inline-block align-center" alt="Logo" width="130px">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text=Rólunk>
            <b-dropdown-item :to="{path:  '/about'}">Rólunk</b-dropdown-item>
            <b-dropdown-item :to="{path:  '/documents'}">Dokumentumok</b-dropdown-item>
            <b-dropdown-item :to="{path:  '/donation'}">Támogatás</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Védenceink" right>
            <b-dropdown-item :to="{path: '/rescues/looking-for-owner'}">Gazdikereső állatok</b-dropdown-item>
            <b-dropdown-item :to="{path: '/rescues/pending-adoption'}">Gazdijelöltes állatok</b-dropdown-item>
            <b-dropdown-item :to="{path: '/rescues/adopted'}">Gazdira találtak</b-dropdown-item>
            <b-dropdown-item :to="{path: '/rescues/deceased'}">In memoriam</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item :to="{path:  '/news'}">Hírek</b-nav-item>
          <b-nav-item target="_blank" href="https://www.facebook.com/pg/endogegyesulet/events/">Események</b-nav-item>
          <b-nav-item :to="{path:  '/good-to-know'}">Jó tudni</b-nav-item>
          <b-nav-item :to="{path:  '/contact'}">Kapcsolat</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto donate-button">
          <b-nav-form>
            <b-nav-item>
              <b-button variant="success" size="lg" class="mx-1 button-color">Támogass!</b-button>
            </b-nav-item>
          </b-nav-form>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style>
.donate-button{
  z-index: 9999;
}
</style>
