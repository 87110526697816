<template>
  <div class="main">
    <Navbar/>
    <Socials/>
    <div class="py-5">
      <div class="py-5">
        <div class=py-5>
          <b-row>
            <b-col cols="3"></b-col>
            <b-col cols="6">
              <p><b>Munkánkat többféle módon támogathatod:</b></p>
              <p class="py-3 text-left">
                <br>
                <br>
                1./ Egyrészt önkéntes segítőként, kutyasétáltatóként, egyéb segítségekkel.<br>
                2./ Ideiglenes gazdiként.<br>
                3./ Rendezvényeken való részvétellel, vagy fuvarral, ha éppen kutyát kell szállítani.<br>
                <br>
                Ha időd vagy lehetőséged nincs, de mégis segítenél, azt megteheted tárgyi adománnyal, illetve utalással.<br>
                <br>
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th scope="row">Banki adatok</th>
                  <td>Bank:OTP Bank Nyrt.<br>Számlaszám: 11745114-23915297<br>IBAN: HU22117451142391529700000000<br>SWIFT KÓD: OTPVHUHB
                  </td>
                </tr>
                <tr>
                  <th scope="row">Paypal</th>
                  <td>endogegyesulet@gmail.com
                  </td>
                </tr>
                <tr>
                  <th scope="row">Amazon Wishlist</th>
                  <td><a target="_blank"
                         href="https://www.amazon.de/-/en/hz/wishlist/ls/OL62X82OK998?fbclid=IwAR0MCxIOw-Hrg45GIfhZWLBVFNoqDbuYlT2jnKHBCzGb1Ym9rdS-US3_odE">Megnézem</a>
                  </td>
                </tr>
                </tbody>
              </table>
              Az Amazon Wishlist-en, Facebook és Instagram oldalunkon tájékoztatást adunk az aktuális szükségletekről,
              kérésekről.<br>
              Amire mindig szükségünk van: minőségi kutya és cica száraztáp (felnőtt, junior, senior), kutya és cica
              konzerv, rágórúd, snack, immunerősítő/májvédő vitaminok, gyomorvédő, Domestos, takaró, fűrészpor.<br>
              <br>
              Tárgyi adományokat postán, vagy Budapesten és Jászkiséren személyesen, előzetes egyeztetéssel tudunk
              átvenni.

              </p>
            </b-col>
            <b-col cols="3"></b-col>
          </b-row>
        </div>
      </div>
      <b-row align-v="center" align="center" align-content="center">
        <b-col>
          <img width="960" height="541" src="https://endog-public.s3.eu-west-1.amazonaws.com/endog-donations.jpeg">
        </b-col>
      </b-row>
    </div>
    <Footer/>
  </div>
</template>


<script>

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Socials from "../components/Socials";

export default {
  name: 'About',
  props: {},
  components: {
    Footer,
    Navbar,
    Socials
  },
  data() {
    document.body.style.backgroundColor = "#FFFFFF";
  }
}
</script>

<style>
</style>